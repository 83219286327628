@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*=======================================
  ホーム
=======================================*/
#p__home {
  width: 100%;
  #first_view {
    position: relative;
    top: 0;
    left: 0;
    width: min(100vw, 100%);
    height: f.static_svh(100);
    height: 100svh;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.5s;
    overflow: hidden;

    // overlay_bgとmix-blend-modeを適用させないためにfixedを指定
    .animation_elements {
      position: absolute;
      position: fixed;
      top: 0;
      left: 0;
      width: min(100vw, 100%);
      height: f.static_svh(100);
      height: 100svh;
      z-index: 1;
    }

    .fv_bg_grad {
      position: absolute;
      &.red {
        width: 220vmax;
        height: 250vmax;
        top: 50%;
        left: 50%;
        animation-name: fv_bg_grad_red;
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-fill-mode: forwards; // 終わったままの状態にする
        mix-blend-mode: lighten;
        filter: blur(30px);
        @keyframes fv_bg_grad_red {
          0% {
            opacity: 1;
            transform: translate(-70%, -60%) rotate(0deg);
            transform-origin: 45% 50%;
          }
          40% {
            opacity: 1;
          }
          50% {
            transform-origin: 45% 55%;
          }
          100% {
            opacity: 0;
            transform: translate(-40%, -40%) rotate(-360deg);
            transform-origin: 55% 45%;
          }
        }
      }
      &.blue {
        width: 240vmax;
        height: 250vmax;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: 3s;
        filter: blur(30px);
        &.active {
          animation: fv_bg_grad_blue 20s cubic-bezier(0.91, 0.92, 0.87, 1) infinite;
          opacity: 1;
        }
        @keyframes fv_bg_grad_blue {
          0% {
            transform: translate(-40%, -40%) rotate(0deg);
            transform-origin: 55% 45%;
          }
          30% {
            transform: translate(-60%, -60%) rotate(-180deg);
            transform-origin: 45% 55%;
          }
          100% {
            transform: translate(-40%, -40%) rotate(-360deg);
            transform-origin: 55% 45%;
          }
        }
      }
    }

    .blob_wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      &.white {
        transform: translate(-50%, -50%) scale(10%);
        transition: 4s cubic-bezier(0.29, -0.37, 0, 1.1);
        width: f.clamp_prf(550px, 740px);
        height: f.clamp_prf(550px, 740px);
        z-index: 3;
        &.active {
          transform: translate(-50%, -50%) scale(100%);
        }
        .blob {
          fill: #f0f0f0;
          animation: blob_rotate 25s linear infinite;
          transform-origin: 50% 50%;
        }
      }
      &.corporate_blue {
        opacity: 0;
        transform: translate(-51%, -50%);
        width: f.clamp_prf(550px, 724px);
        height: f.clamp_prf(550px, 724px);
        z-index: 2;
        transition: 1s;
        mix-blend-mode: hard-light;
        &.active {
          opacity: 1;
        }
        .blob {
          fill: v.$blue;
          animation: blob_rotate 35s linear infinite;
          transform-origin: 50% 49%;
        }
      }
      &.blue {
        opacity: 0;
        transform: translate(-49%, -50.5%);
        width: f.clamp_prf(550px, 726px);
        height: f.clamp_prf(550px, 726px);
        z-index: 1;
        transition: 1s;
        mix-blend-mode: hard-light;
        &.active {
          opacity: 1;
        }
        .blob {
          fill: #38bce9;
          animation: blob_rotate 30s linear infinite;
          transform-origin: 50% 50%;
          animation-direction: reverse;
        }
      }
      @keyframes blob_rotate {
        100% {
          transform: rotate(360deg);
        }
      }
    }

    // FVを非表示にする時に拡大させる丸
    .white_circle {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200vmax;
      height: 200vmax;
      transform: translate(-50%, -50%) scale(0%);
      background-color: #f0f0f0;
      border-radius: 300vmax;
      z-index: 1; //ロゴ、コピー、スクロール促すやつよりは下
    }
    .fv_bg_blue {
      position: absolute;
      top: 0;
      left: 0;
      background-color: v.$blue;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .fv_circle_inner {
      display: flex;
      flex-direction: column;
      gap: f.clamp_prf(30px, 50px);
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .fv_logo {
      width: f.clamp_prf(140px, 170px);
      height: auto;
      z-index: 10;
      opacity: 0;
      transition: 2s ease;
      &.active {
        opacity: 1;
      }
    }
    .fv_copy {
      font-size: f.clamp_prf(22px, 32px);
      font-weight: 900;
      color: v.$blue;
      white-space: nowrap;
      z-index: 10;
      opacity: 0;
      transition: 2s ease;
      transition-delay: 0.3s;
      &.active {
        opacity: 1;
      }
    }
    .scrolldown {
      position: absolute;
      z-index: 10;
      font-family: v.$geologica;
      font-size: 16px;
      bottom: -50%;
      left: 50%;
      color: v.$blue;
      letter-spacing: 0.2em;
      writing-mode: vertical-lr;
      white-space: nowrap;
      opacity: 0;
      transition: 2s ease;
      transition-delay: 0.6s;
      &.active {
        opacity: 1;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 2px;
        height: 34px;
        background-color: v.$blue;
        transform: translate(-50%, 0);
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 100vh;
        animation: scrolldown 4s ease infinite;
        background-color: v.$blue;
      }
      @keyframes scrolldown {
        0% {
          opacity: 0;
          transform: translate(-50%, -5px);
        }
        5% {
          opacity: 1;
          transform: translate(-50%, -5px);
        }
        95% {
          opacity: 1;
          transform: translate(-50%, 28px);
        }
        100% {
          opacity: 0;
          transform: translate(-50%, 28px);
        }
      }
    }
  }
  #first_view.scrolled {
    opacity: 0;
    pointer-events: none;
    transition: 0.7s;
    transition-delay: 0.8s; // 丸が拡大するまでの時間を稼ぐ

    // 背景のグラデーションはすぐ消す
    .fv_bg_grad {
      transition: opacity 0.5s;
      opacity: 0;
    }
    .fv_bg_blue {
      transition: opacity 0.5s;
      opacity: 1;
    }
    // 丸が拡大していくアニメーション
    .blob_wrapper {
      &.white {
        transition: transform 0.5s;
        transform: translate(-50%, -50%) scale(95%);
      }
      &.corporate_blue,
      &.blue {
        transition: 0.5s;
        transform: translate(-50%, -50%) scale(50%);
        opacity: 0;
        mix-blend-mode: normal;
      }
    }
    .white_circle {
      animation: name 2.5s ease;
      @keyframes name {
        0% {
          transform: translate(-50%, -50%) scale(0);
        }
        100% {
          transform: translate(-50%, -50%) scale(100%);
          opacity: 1;
        }
      }
    }
  }

  // 二回目以降の表示の場合
  #first_view.revisit {
    position: relative;
    opacity: 1;
    pointer-events: none;
    transition: 0.5s;
    transition-delay: 0s;

    // 固定しない
    .animation_elements {
      position: relative;
    }

    // 赤紫のグラデーションは非表示
    .fv_bg_grad.red {
      display: none;
    }

    // 青緑のグラデーションは始めからアニメーションさせる
    .fv_bg_grad.blue {
      opacity: 1;
      animation: fv_bg_grad_blue 30s cubic-bezier(0.91, 0.92, 0.87, 1) infinite;
    }

    // 丸は始めから拡大した状態にしておく
    .blob_wrapper {
      &.white {
        transform: translate(-50%, -50%) scale(100%);
      }
      &.corporate_blue {
        opacity: 1;
      }
      &.blue {
        opacity: 1;
      }
    }

    // 丸の中身は始めから出す
    .fv_logo {
      opacity: 1;
    }
    .fv_copy {
      opacity: 1;
    }
    .scrolldown {
      opacity: 1;
    }
  }

  // animation_elementsとmix-blend-modeを適用させないためにfixedを指定
  #fv_overlay_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: min(100vw, 100%);
    height: f.static_svh(100);
    height: 100svh;
    background-color: #f0f0f0;
    transition: opacity 0.9s;
    z-index: 1;
    opacity: 1;
    pointer-events: none;
    &.scrolled {
      opacity: 0;
    }
  }

  .recommendation {
    margin: 0 auto;
    padding-top: 160px;

    // 別の画面からWORKSセクション宛に戻ってきた際、
    // 記事が読み込まれていなくてもある程度スクロール位置を正確にするためのもの
    &.js__load_required {
      height: f.clamp_prf(400px, 550px);
      &.loaded {
        height: auto;
      }
      &.failed {
        height: 0;
        display: none;
      }
    }

    .c__head_primary {
      @include m.content_width;
      height: clamp(18px, 4vw, 24px);
      width: auto;
    }
  }
  .recommendation_slider_wrapper {
    position: relative;
    .recommendation_swiper {
      margin: 30px calc((100% - v.$pc_min) / 2 + 100px) 0 0;
      @include m.home_swiper_break {
        margin: 30px v.$body_pd 0 0;
      }
      // ====== 既存のアニメーションを上書き ====== //
      .swiper-wrapper {
        transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
      }
      .swiper-slide {
        width: clamp(380px, 36vw, 630px);
        height: auto;
        @include m.tab {
          width: clamp(400px, 60vw, 500px);
        }
        @include m.sp {
          width: min(calc(100vw - (v.$body_pd * 2)), 370px);
        }
        .anchor {
          display: block;
          width: 100%;
          height: 100%;
          .article_info {
            display: block;
            position: relative;
            overflow: hidden;
            pointer-events: none;
            border-radius: 12px;
            &:before {
              content: "";
              display: block;
              padding-top: v.$ratio_16_9;
            }
            &:after {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background-color: #0064d499;
              opacity: 0;
              pointer-events: none;
              transition: opacity 0.2s;
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .info {
              position: absolute;
              bottom: 0;
              left: 0;
              padding: 0 20px 20px 20px;
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: flex-start;
              font-size: f.clamp_prf(12px, 14px);
              color: #fff;
              z-index: 1;
              font-weight: v.$fw_semibold_600;
              direction: ltr; //スライダーにrtlを指定すると、テキストの方向もそうなってしまうのでそれを防ぐ
              unicode-bidi: bidi-override; // 上の direction を強制的に適用
              opacity: 0;
              transition: opacity 0.2s;
              .title {
                @include m.line_clamp(1);
              }
            }
          }
          &.active {
            transform: scale(120%);
            transition: 0.3s;
            img {
              opacity: 0;
              filter: blur(10px);
              transition: 0.3s;
            }
          }
          @include m.hover_device {
            &:hover {
              .article_info {
                &:after {
                  opacity: 1;
                }
                .info {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .swiper-pagination {
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .swiper-button-prev {
        display: none;
      }
      .swiper-button-next {
        display: none;
      }
    }
    #recommendation_swiper_next {
      position: absolute;
      right: calc((100% - v.$pc_min) / 2);
      bottom: 0;
      margin-right: 20px;
      .c__navigation_button {
        width: clamp(40px, 8vw, 60px);
        height: clamp(40px, 8vw, 60px);
      }
      @include m.home_swiper_break {
        margin-right: 0;
        right: v.$body_pd;
        bottom: clamp(-120px, -16vw, -88px);
      }
    }
    #recommendation_swiper_pagination {
      position: absolute;
      top: 10px;
      right: calc((100% - v.$pc_min) / 2);
      margin-right: 36px;
      width: fit-content;
      height: fit-content;
      left: auto;
      display: flex;
      flex-direction: column;
      gap: 2px;
      .swiper-pagination-bullet {
        opacity: 1;
        background: none;
        font-family: v.$geologica;
        font-size: 14px;
        font-weight: v.$fw_semibold_600;
        writing-mode: vertical-rl;
        text-orientation: sideways;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px; // クリック可能な範囲を広げる
        cursor: pointer;
        &.swiper-pagination-bullet-active {
          color: v.$blue;
        }
      }
      @include m.home_swiper_break {
        margin-right: 0;
        right: v.$body_pd;
        bottom: clamp(-38px, -5vw, -30px);
        flex-direction: row;
        top: auto;
        gap: 4px;
        .swiper-pagination-bullet {
          writing-mode: initial;
          text-orientation: initial;
          font-size: clamp(16px, 3vw, 20px);
          padding: 6px; // クリック可能な範囲を広げる
        }
      }
    }
  }

  .service_topics_section {
    padding-top: clamp(114px, 18vw, 174px);
    display: flex;
    width: 100%;
    @include m.tab {
      flex-direction: column;
      gap: 60px;
    }
    .service_list_wrapper {
      // width: f.clamp_prf(546px, 600px);
      // container-type: inline-size;
      border-right: 1px solid #d9d9d9;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-right: clamp(50px, 5vw, 100px);
      flex-shrink: 0;
      @include m.tab {
        padding: 0;
        border-right: none;
      }
      // padding-right: 10cqw;
      .service_list {
        margin-top: f.clamp_prf(20px, 42px);
        display: flex;
        flex-direction: column;
        gap: clamp(23px, 1.9vw, 25px);
        .service_item {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          gap: 26px;
          @include m.tab {
            gap: 20px;
          }
          @include m.hover_device {
            &:hover {
              .c__navigation_button {
                transform: scale(1.1);
              }
            }
          }
        }
        .text_wrapper {
          display: flex;
          gap: f.clamp_prf(10px, 20px);
          align-items: flex-end;
          width: 100%;
          @include m.tab {
            width: 330px;
          }
          @include m.sp {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
          }
        }
        .title {
          color: v.$blue;
          font-size: f.clamp_prf(18px, 28px);
          font-weight: v.$fw_bold_700;
          flex-shrink: 0;
          white-space: nowrap;
        }
        .description {
          font-size: f.clamp_prf(12px, 16px);
          flex-shrink: 0;
          white-space: nowrap;
          font-weight: v.$fw_bold_700;
          @include m.sp_s {
            font-size: 10px;
            letter-spacing: -0.05em;
          }
        }
        .c__navigation_button {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
        }
      }
    }
    .topics_list_wrapper {
      width: 100%;
      padding-left: clamp(50px, 5vw, 100px);
      padding-top: 14px;
      padding-bottom: 14px;
      @include m.tab {
        padding: 0;
      }
      .heading_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include m.tab {
          justify-content: flex-start;
          gap: 20px;
        }
        @include m.sp {
          justify-content: space-between;
        }
        .text {
          font-size: f.clamp_prf(12px, 12px);
          letter-spacing: 0.24px;
          font-weight: v.$fw_semibold_600;
          color: v.$blue;
          margin-bottom: clamp(8px, 1vw, 38px);
        }
      }
      .topics_list {
        margin-top: f.clamp_prf(16px, 30px);
        display: flex;
        flex-direction: column;
        gap: f.clamp_prf(14px, 16px);
      }
      .topics_item {
        display: flex;
        flex-direction: column;
        gap: f.clamp_prf(8px, 8px);
        .date {
          font-size: f.clamp_prf(12px, 12px);
          font-weight: v.$fw_bold_700;
          white-space: nowrap;
        }
        .title {
          font-size: f.clamp_prf(14px, 14px);
          font-weight: v.$fw_bold_700;
          color: v.$blue;
          @include m.line_clamp(1);
        }
      }
    }
  }

  .home_works {
    @include m.content_width;

    // アンカー位置調整のため、marginとpadding両方で上の余白を調整
    margin: 10px auto f.clamp_prf(120px, 284px);
    padding-top: 60px;
    .c__head_primary {
      margin: 0 auto;
    }
    .c__categories_list {
      margin: clamp(40px, 8vw, 80px) auto 0;
    }
    .c__articles_grid {
      margin: f.clamp_prf(40px, 60px) auto 0;
    }
  }
}

/*=======================================
  WORKS詳細
=======================================*/
#p_works_show {
  padding-top: clamp(100px, 20vw, 140px);
  .c__article_wrapper {
    max-width: clamp(950px, 78vw, 1110px);
    width: 100%;
    padding-left: clamp(30px, 7vw, 80px);
    padding-right: clamp(30px, 7vw, 80px);
    margin-left: auto;
    margin-right: auto;
  }
  .c__article_body {
    @include m.sp {
      p {
        line-height: 180%;
      }
    }
  }

  .related_works_slider_wrapper {
    margin: 156px auto 180px;
    position: relative;
    &.failed {
      height: 0;
      display: none;
    }
    .related_works_swiper {
      .swiper-wrapper {
        transition-timing-function: linear;
      }
      .swiper-slide {
        width: 356px;
        width: min(calc(100vw - (v.$body_pd * 2)), 356px);
        .anchor {
          display: block;
          width: 100%;
          height: 100%;
          .article_info {
            display: block;
            position: relative;
            overflow: hidden;
            pointer-events: none;
            border-radius: 12px;
            &:before {
              content: "";
              display: block;
              padding-top: v.$ratio_16_9;
            }
            &:after {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background-color: #0064d499;
              opacity: 0;
              pointer-events: none;
              transition: opacity 0.2s;
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .info {
              position: absolute;
              bottom: 0;
              left: 0;
              padding: 0 20px 20px 20px;
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: flex-start;
              font-size: f.clamp_prf(12px, 14px);
              color: #fff;
              z-index: 1;
              font-weight: v.$fw_semibold_600;
              direction: ltr; //スライダーにrtlを指定すると、テキストの方向もそうなってしまうのでそれを防ぐ
              unicode-bidi: bidi-override; // 上の direction を強制的に適用
              opacity: 0;
              transition: opacity 0.2s;
              .title {
                @include m.line_clamp(1);
              }
            }
          }
          &.active {
            transform: scale(110%);
            transition: 0.3s;
            img {
              opacity: 0;
              filter: blur(10px);
              transition: 0.3s;
            }
          }
          @include m.hover_device {
            &:hover {
              .article_info {
                &:after {
                  opacity: 1;
                }
                .info {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .swiper-pagination {
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .swiper-button-prev {
        display: none;
      }
      .swiper-button-next {
        display: none;
      }
    }

    .related_works_navigation {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: f.clamp_prf(30px, 50px);
      align-items: center;
      p {
        font-size: f.clamp_prf(14px, 16px);
        font-weight: v.$fw_semibold_600;
        font-family: v.$geologica;
        margin-top: 4px;
        width: max-content;
      }
    }
  }
}

/*=======================================
  ABOUT
=======================================*/
#p__about {
  .about_profile {
    @include m.content_width(v.$content_width_m);

    .c__head_primary {
      margin: 0 auto;
    }
    .about_profile_inner {
      padding-top: f.clamp_prf(32px, 80px);
      display: flex;
      gap: 40px;
      justify-content: center;
      @include m.tab {
        flex-direction: column;
        align-items: center;
      }
    }
    .profile_l {
      position: relative;
      height: fit-content;
      .profile_image {
        width: clamp(180px, 18.77vw, 260px);
        height: clamp(180px, 18.77vw, 260px);
        object-fit: cover;
        border-radius: 100vh;
      }
      .scroll_to_creator {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        top: 50%;
        left: clamp(-126px, -8vw, -40px);
        transform: translate(0, -50%);
        cursor: pointer;
        span {
          @include m.text_vertical;
          font-family: v.$geologica;
          font-weight: v.$fw_semibold_600;
          font-size: 16px;
          margin-left: 4px;
        }
        .c__navigation_button {
          width: 20px;
          height: 20px;
        }
        @include m.hover_device {
          &:hover {
            .c__navigation_button {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .profile_r {
      width: 100%;
      max-width: 450px;
      p {
        font-weight: v.$fw_bold_700;
        font-size: f.clamp_prf(14px, 16px);
        line-height: 230%;
        margin-bottom: 1em;
      }
      strong {
        color: v.$blue;
        font-size: f.clamp_prf(14px, 20px);
      }
      @include m.tab {
        max-width: 320px;
        .u__ib {
          display: inline;
        }
      }
    }
  }

  .about_topics {
    @include m.content_width(v.$content_width_m);
    padding-top: f.clamp_prf(80px, 140px);
    .c__head_primary {
      margin: 0 auto;
    }
    .about_topics_wrapper {
      padding-top: f.clamp_prf(40px, 80px);
    }
    .about_topics_inner {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 600px;
      font-size: 14px;

      &.overflow {
        overflow-y: scroll;
        padding-right: 20px;
        max-height: calc(2 * 2.14 * 2.5em); // gap * line-height * 行数
        @include m.sp {
          max-height: calc(2 * 2.14 * 4.5em); // gap * line-height * 行数
        }
        // スクロールバーをカスタマイズ
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: v.$blue;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-track {
          background-color: #cccccc;
          border-radius: 4px;
        }
      }

      .topics_item {
        display: flex;
        gap: 2em;
        align-items: baseline;
        transition: 0.3s;
        @include m.hover_device {
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .date {
        width: 60px;
        font-size: 1em;
        white-space: nowrap;
        font-weight: v.$fw_bold_700;
      }
      .title {
        width: 100%;
        color: v.$blue;
        font-size: 1em;
        @include m.line_clamp(2);
        font-family: v.$noto_yakuhan;
        letter-spacing: 0.05em;
        font-weight: v.$fw_bold_700;
        line-height: 2.14em;
      }
      @include m.sp {
        gap: 26px;
        .topics_item {
          gap: 10px;
          flex-direction: column;
        }
        .title {
          @include m.line_clamp(2);
          line-height: 171.4%;
        }
      }
    }
  }

  .about_creator_list_wrapper {
    @include m.content_width(v.$content_width_m);
    padding-top: f.clamp_prf(80px, 148px);
    @include m.sp {
      padding-left: 0;
      padding-right: 0;
    }
    .c__head_primary {
      margin: 0 auto;
    }
    .about_creator_list {
      margin: f.clamp_prf(40px, 74px) auto 0;
      display: grid;
      grid-auto-rows: minmax(40px, auto);
      grid-template-columns: repeat(auto-fill, minmax(clamp(58px, 14vw, 120px), 1fr));
      gap: f.clamp_prf(50px, 80px) clamp(26px, 6vw, 120px);
      width: clamp(360px, 60vw, 680px);
      justify-content: center;
      @include m.sp {
        gap: clamp(50px, 5.78vw, 80px) clamp(18px, 11vw, 60px);
        width: clamp(280px, 86%, 500px);
      }
      .creator_button {
        position: relative;
        padding-bottom: 34px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .position {
          color: v.$blue;
          font-size: f.clamp_prf(10px, 14px);
          font-weight: v.$fw_bold_700;
          font-family: v.$geologica;
          pointer-events: none;
          white-space: nowrap;
        }
        .name {
          padding-top: f.clamp_prf(10px, 20px);
          font-size: f.clamp_prf(20px, 32px);
          font-weight: v.$fw_bold_700;
          pointer-events: none;
          white-space: nowrap;
        }
        &:before {
          content: "";
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translateX(-50%);
          width: 6px;
          height: 6px;
          border-radius: 100vh;
          background-color: v.$blue;
          transition: transform 0.15s;
        }
        &.active {
          &:before {
            transform: translateX(-50%) scale(1.6);
          }
        }
        @include m.hover_device {
          &:hover {
            &:before {
              transform: translateX(-50%) scale(1.5);
            }
          }
        }
      }
    }
  }
  .about_creator_slider_wrapper {
    @include m.content_width(v.$content_width_m);
    margin-top: 60px;
    position: relative;
    width: fit-content;
    @include m.sp {
      width: 100%; // スライダーを横幅いっぱいにする
      margin-top: 92px;
    }
    // ====== 既存のアニメーションを上書き ====== //
    .swiper-wrapper {
      transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    }
    .about_creator_swiper {
      width: f.clamp_prf(500px, 800px);
      $creator_icon_size: clamp(80px, 11vw, 140px);
      $creator_item_gap: f.clamp_prf(10px, 38px);
      $creator_item_ml: calc($creator_icon_size + $creator_item_gap);
      @include m.sp {
        width: auto;
      }
      .creator_item {
        background-color: #fff;
        padding-top: f.clamp_prf(20px, 48px);
        padding-right: f.clamp_prf(20px, 90px);
        padding-bottom: f.clamp_prf(30px, 50px);
        padding-left: f.clamp_prf(20px, 48px);
        border-radius: f.clamp_prf(12px, 20px);
        border-radius: 20px;
        height: auto;
        position: relative;
      }
      .sns_info {
        position: absolute;
        top: clamp(26px, 5vw, 66px);
        right: clamp(20px, 5vw, 90px);
        width: auto;
        height: auto;
        display: flex;
        gap: clamp(16px, 3vw, 30px);
        align-items: center;
        justify-content: center;
        .yt {
          width: clamp(30px, 5vw, 40px);
        }
        .tw {
          width: clamp(20px, 4vw, 28px);
        }
        @media (max-width: 400px) {
          top: clamp(26px, 40vw, 120px);
          right: auto;
          left: 24px;
        }
      }
      .head_info {
        display: flex;
        gap: $creator_item_gap;
        .info_l {
          // プロフィールアイコン
          width: $creator_icon_size;
          height: $creator_icon_size;
          object-fit: contain;
        }
        .info_r {
          margin: 20px auto 60px;
          width: 100%;
          @include m.tab {
            margin-bottom: 0;
          }
          .name_en {
            display: block;
            font-size: f.clamp_prf(10px, 14px);
            font-weight: v.$fw_bold_700;
            margin-bottom: 10px;
          }
          .name {
            display: inline-block;
            font-size: f.clamp_prf(20px, 36px);
            font-weight: v.$fw_bold_700;
            margin-right: 10px;
          }
          .position {
            display: inline-block;
            font-size: f.clamp_prf(10px, 16px);
            font-weight: v.$fw_bold_700;
            color: v.$blue;
          }
        }
      }
      .profile {
        margin-top: -26px;
        margin-left: $creator_item_ml;
        font-size: f.clamp_prf(14px, 16px);
        font-weight: v.$fw_bold_700;
        line-height: 225%;
        @include m.tab {
          margin-top: 26px;
          margin-left: 0;
        }
        @media (max-width: 400px) {
          margin-top: 60px;
          &.no_sns_icon {
            margin-top: 20px;
          }
        }
        &.js__will_elipsis.elipsis_active {
          // 省略の行数はある程度JSと合わせる
          @include m.line_clamp(5);
          @include m.tab {
            @include m.line_clamp(5);
          }
          @include m.sp {
            @include m.line_clamp(8);
          }
        }
      }
      .career {
        margin-top: f.clamp_prf(20px, 34px);
        margin-left: $creator_item_ml;
        font-size: 14px;
        font-weight: v.$fw_regular_400;
        line-height: 258.6%;
        white-space: pre-line; // 改行を反映
        line-height: 1.8;
        @include m.tab {
          margin-left: 0;
        }
        &.js__will_elipsis.elipsis_active {
          // 省略の行数はある程度JSと合わせる
          @include m.line_clamp(10);
          @include m.tab {
            @include m.line_clamp(10);
          }
          @include m.sp {
            @include m.line_clamp(10);
          }
        }
      }
      .c__navigation_button {
        display: none;
        width: 20px;
        height: 20px;
        margin: f.clamp_prf(10px, 14px) auto 0;
        img {
          transform: translate(-50%, -50%) rotate(90deg);
        }
        &.active {
          display: block;
        }
        &.spread {
          img {
            transform: translate(-50%, -50%) rotate(-90deg);
          }
        }
        @include m.hover_device {
          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }

    #about_creator_swiper_prev {
      position: absolute;
      top: 50%;
      left: -40px;
      transform: translateY(-50%);
      pointer-events: none; // 中身のボタンにeventsがわたるようにする
      @include m.sp {
        top: -50px;
        left: v.$body_pd;
        transform: none;
        .c__navigation_button {
          width: 40px;
          height: 40px;
        }
      }
    }
    #about_creator_swiper_next {
      position: absolute;
      top: 50%;
      right: -40px;
      transform: translateY(-50%);
      pointer-events: none; // 中身のボタンにeventsがわたるようにする
      @include m.sp {
        top: -50px;
        right: v.$body_pd;
        transform: none;
        .c__navigation_button {
          width: 40px;
          height: 40px;
        }
      }
    }
    // }
  }

  .about_company_information {
    @include m.content_width(v.$content_width_m);
    margin: 0 auto f.clamp_prf(80px, 200px);
    padding-top: f.clamp_prf(60px, 80px);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0 20px;
    font-size: clamp(12px, 3vw, 14px);
    @include m.sp {
      max-width: 400px;
    }

    .company_information_item {
      width: calc(50% - 20px);
      display: flex;
      padding: 22px 20px;
      position: relative;
      align-items: center;
      @include m.sp {
        width: 100%;
        padding: 22px 10px 22px 20px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-image: url(/assets/img/about/company_line.svg);
        background-size: auto;
        background-repeat: no-repeat;
      }
      &.bottom_decoration {
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-image: url(/assets/img/about/company_line.svg);
          background-size: auto;
          background-repeat: no-repeat;
        }
      }
      &.no_decoration {
        padding: 0 0 0 20px;
        &:before {
          content: none;
        }
        @include m.sp {
          padding: 22px 10px 22px 20px;
        }
      }
      &.sp_deco_bottom_none {
        @include m.sp {
          &:after {
            content: none;
          }
        }
      }
    }
    .head {
      width: 74px;
      font-size: f.clamp_prf(12px, 14px);
      font-weight: v.$fw_bold_700;
      white-space: nowrap;
      line-height: 257.143%;
      flex-shrink: 0; // 縮まなくする
      &.section_head {
        color: v.$blue;
        line-height: 1;
        margin: 0 auto;
        width: fit-content;
      }
      @include m.sp {
        width: 70px;
      }
    }
    .description {
      font-size: f.clamp_prf(12px, 14px);
      font-weight: v.$fw_regular_400;
      line-height: 257.143%;
    }
  }
}

/*=======================================
  TOPICS一覧
=======================================*/
#p__topics_index {
  @include m.content_width;
  margin-bottom: f.clamp_prf(80px, 200px);
  .c__head_primary {
    margin: 0 auto;
  }
  .c__topics_category_list {
    margin-top: f.clamp_prf(20px, 84px);
  }
  .article_section_wrapper {
    margin-top: f.clamp_prf(20px, 64px);
    display: flex;
    gap: f.clamp_prf(36px, 64px);
    @include m.tab {
      flex-direction: column-reverse;
    }
  }
  .article_section_l {
    flex-grow: 1;
  }
  .article_section_r {
    width: f.clamp_prf(160px, 200px);
    flex-shrink: 0;
    @include m.tab {
      width: 100%;
    }
  }
  .c__topics_list {
  }
  .topics_tag_scroll_box {
    display: contents;
    @include m.tab {
      overflow-x: auto;
      display: block;
      @include m.custom_scrollbar;
      // padding: 0.5em;
    }
  }
  .c__topics_tag_list {
    width: 100%;
    // padding-bottom: 10px;
    @include m.tab {
      background: #d9d9d9;
      width: max-content;
      flex-wrap: nowrap;
      padding: 10px 12px;
      margin-bottom: 8px;
      // .category_buttons {
      //   -webkit-line-clamp: none;
      //   white-space: nowrap;
      // }
    }
  }
  .c__pager {
    margin-top: f.clamp_prf(40px, 80px);
  }
}

/*=======================================
  TOPICS詳細
=======================================*/
#p__topics_show {
  padding-top: clamp(100px, 20vw, 140px);
  margin-bottom: f.clamp_prf(50px, 136px);
  .article_wrapper {
    // max-width: clamp(950px, 78vw, 1110px);
    @include m.content_width(800px);
    // width: 100%;
    // padding-left: clamp(30px, 7vw, 80px);
    // padding-right: clamp(30px, 7vw, 80px);
    // margin-left: auto;
    // margin-right: auto;
  }
  .article_head_info {
    display: flex;
    align-items: baseline;
    gap: 22px;
    @include m.tab {
      flex-direction: column;
    }
  }
  .article_head_info_date {
    white-space: nowrap;
    font-weight: v.$fw_bold_700;
    line-height: 1.5;
    font-size: f.clamp_prf(14px, 14px);
    transform: translateY(1px);
  }
  .article_head_info_hashtags {
    font-size: f.clamp_prf(11px, 12px);
    display: flex;
    flex-wrap: wrap;
    gap: 6px f.clamp_prf(12px, 15px);
    font-weight: v.$fw_bold_700;
    @include m.sp {
      gap: 6px 12px;
    }
    .hashtag {
      line-height: 1.5;
    }
  }
  .article_title {
    margin-top: 10px;
    font-weight: v.$fw_bold_700;
    font-size: f.clamp_prf(16px, 23px);
    line-height: 147.826%;
    @include m.line_clamp(2);
    color: v.$blue;
  }
  .topics_item_decorations {
    margin-top: f.clamp_prf(30px, 62px);
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 100vh;
      background-color: v.$blue;
    }
    .line {
      position: relative;
      height: 1px;
      width: 100%;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #d9d9d9;
      }
      width: 100%;
    }
  }
  .c__article_body {
    margin-top: f.clamp_prf(30px, 48px);
    @include m.sp {
      p {
        line-height: 180%;
      }
    }
  }
  .bottom_section {
    @include m.content_width(1100px);
    margin-top: f.clamp_prf(90px, 166px);
    display: flex;
    gap: f.clamp_prf(34px, 86px);

    &.no_related {
      @include m.content_width(800px); // 記事の幅に合わせる
    }
    @include m.tab {
      flex-direction: column;
    }
    .other_topics_wrapper {
      flex-grow: 1;
      .c__topics_list .topics_item {
        .topics_title {
          @include m.sp {
            margin-top: 14px;
          }
        }
        .hashtags {
          @include m.line_clamp(1);
          @include m.sp {
            @include m.line_clamp(2);
          }
        }
        .topics_item_decorations {
          margin-top: 0;
          .dot {
            display: none;
          }
          @include m.sp {
            margin-top: 30px;
          }
        }
      }
      .other_topics_view_all.u__sp_none {
        font-family: v.$geologica;
        font-weight: v.$fw_semibold_600;
        font-size: f.clamp_prf(14px, 14px);
        display: flex;
        gap: 14px;
        align-items: center;
        justify-content: flex-end;
        margin-top: f.clamp_prf(40px, 48px);
        .c__navigation_button {
          width: 30px;
          height: 30px;
        }
        @include m.sp {
          display: none;
        }
        @include m.hover_device {
          &:hover {
            .c__navigation_button {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .category_tag_wrapper {
      width: 370px;
      flex-shrink: 0;
      @include m.tab {
        width: 100%;
      }
      .c__topics_category_list {
        width: 270px;
        flex-wrap: wrap;
        gap: f.clamp_prf(10px, 12px) 0;
        li {
          width: 50%;
        }
        @include m.tab {
          width: 320px;
        }
        @include m.sp {
          width: 100%;
        }
      }
      .c__topics_tag_list {
        margin-top: f.clamp_prf(20px, 30px);
        font-size: 12px;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        // li {
        //   display: inline-block;
        //   width: min-content;
        //   .category_buttons {
        //     white-space: nowrap;
        //   }
        // }
        @include m.sp {
          font-size: 14px;
        }
      }
    }
  }
  .view_all_nav.sp_only {
    display: none;
    font-family: v.$geologica;
    font-weight: v.$fw_semibold_600;
    font-size: f.clamp_prf(14px, 14px);
    gap: 14px;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: f.clamp_prf(40px, 48px) auto 0;
    .c__navigation_button {
      width: 30px;
      height: 30px;
    }
    @include m.hover_device {
      &:hover {
        .c__navigation_button {
          transform: scale(1.1);
        }
      }
    }
    @include m.sp {
      display: flex;
    }
  }
}

/*=======================================
  ヲタきち不動産
=======================================*/
#p__virtual_estate {
  position: relative;
  padding-bottom: clamp(50px, 6.5vw, 160px);
  .roof_img_pc {
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: max(100%, 2560px);
  }
  .roof_img_sp {
    display: none;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: clamp(580px, 120vw, 720px);
  }
  @include m.sp {
    .roof_img_pc {
      display: none;
    }
    .roof_img_sp {
      display: block;
    }
  }
  .c__head_primary {
    margin: 0 auto;
  }
  .vurtual_estate_inner {
    margin: f.clamp_prf(40px, 80px) auto 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 52px;
    justify-content: center;
    @include m.tab {
      gap: clamp(50px, 7vw, 54px);
      flex-direction: column;
      align-items: center;
    }
  }

  // ====== 不動産の一覧 ====== //
  .vurtual_estate_list {
    width: 50%;
    max-width: clamp(410px, 38vw, 520px);
    @include m.tab {
      width: 100%;
      max-width: clamp(190px, 58vw, 400px);
    }
    .estate_list_slides {
      cursor: pointer;
      @include m.hover_device {
        &:hover {
          .estate_thumbnail_wrapper:after {
            opacity: 0.7;
          }
        }
      }
    }

    // ====== スライダーの中身のサイズの制御 ====== //
    .estate_list_slide_inner {
      width: clamp(188px, 17.33vw, 240px);
      height: clamp(212px, 18.77vw, 260px);
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      pointer-events: none;
      @include m.tab {
        width: clamp(88px, 26vw, 184px);
        height: clamp(98px, 29vw, 204px);
      }
    }
    .estate_thumbnail_wrapper {
      position: relative;
      display: block;
      z-index: 1;
      width: 100%;
      padding-top: v.$ratio_1_1;
      pointer-events: none;

      // ====== 上の小さい丸 ====== //
      &:before {
        content: "";
        display: block;
        width: clamp(4px, 1vw, 8px);
        height: clamp(4px, 1vw, 8px);
        background-color: v.$blue;
        position: absolute;
        top: clamp(-8px, -1vw, -4px);
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100vh;
        z-index: 2;
      }

      // ====== 画像のホバー ====== //
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: v.$blue;
        width: 90%;
        height: 90%;
        border-radius: f.clamp_prf(12px, 20px);
        z-index: 2;
        opacity: 0;
        transition: opacity 0.2s;
      }

      // ====== 画像 ====== //
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 90%;
        border-radius: f.clamp_prf(12px, 20px);
        object-fit: cover;
      }
    }
    .estate_list_navigations_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      margin-top: 20px;
      .estate_list_swiper_pagination {
        width: fit-content;
      }
      @include m.tab {
        margin-top: 0;
      }
    }

    // ====== 表示非表示の制御 ====== //
    .estate_list_swiper_pc,
    #estate_list_swiper_prev_pc,
    #estate_list_swiper_next_pc,
    #estate_list_swiper_pagination_pc {
      @include m.tab {
        display: none;
      }
      .c__navigation_button {
        width: 20px;
        height: 20px;
      }
    }
    .estate_list_swiper_tab,
    #estate_list_swiper_prev_tab,
    #estate_list_swiper_next_tab,
    #estate_list_swiper_pagination_tab {
      display: none;
      @include m.tab {
        display: block;
      }
      .c__navigation_button {
        width: 40px;
        height: 40px;
      }
    }

    // ====== ナビゲーション系の位置の制御(タブ用) ====== //
    .tab_swiper_wrapper {
      position: relative;
      width: 100%;
    }

    #estate_list_swiper_prev_tab {
      position: absolute;
      left: -56px;
      top: 50%;
      transform: translateY(-50%);
    }

    #estate_list_swiper_next_tab {
      position: absolute;
      right: -56px;
      top: 50%;
      transform: translateY(-50%);
    }

    #estate_list_swiper_pagination_tab {
      position: absolute;
      bottom: -22px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // ====== 不動産の詳細、画像とタイトル、説明 ====== //
  .vurtual_estate_detail {
    width: 50%;
    max-width: clamp(396px, 36.1vw, 550px);
    position: relative;
    &.js__switch {
      transition: 0.6s ease;
      pointer-events: auto;
      &.loading {
        opacity: 0;
        filter: brightness(150%);
        pointer-events: none;
      }
    }

    @include m.tab {
      width: 100%;
      max-width: none;
      padding: 0 v.$body_pd;
    }
    .estate_detail_slides {
      position: relative;
      display: block;
      padding-top: v.$ratio_16_9;
    }

    .estate_detail_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: f.clamp_prf(12px, 20px);
    }

    .virtual_estate_logo {
      position: absolute;
      top: clamp(180px, 16vw, 260px);
      right: 18px;
      z-index: 1;
      width: clamp(80px, 8vw, 120px);
      height: clamp(80px, 9vw, 120px);
      @include m.tab {
        top: clamp(180px, 39vw, 380px);
        right: calc(clamp(8px, 4vw, 20px) + v.$body_pd);
        width: clamp(60px, 18vw, 120px);
        height: clamp(60px, 18vw, 120px);
      }
      @include m.sp {
        top: clamp(120px, 38vw, 400px);
      }
    }

    #estate_detail_swiper_pagination {
      margin: 10px auto 0;
      width: fit-content;
    }

    .estate_info {
      margin: f.clamp_prf(30px, 40px) auto 0;
      .title {
        font-size: f.clamp_prf(16px, 24px);
        font-weight: v.$fw_bold_700;
        color: v.$blue;
      }
      .description {
        margin-top: 20px;
        font-size: f.clamp_prf(14px, 16px);
        font-weight: 700;
        line-height: 225%; /* 225% */
      }
    }
  }
}

/*=======================================
  お問い合わせ
=======================================*/
#p__contact {
  margin-bottom: f.clamp_prf(80px, 200px);
  .c__head_primary {
    margin: 0 auto;
  }
  .contact_inner {
    @include m.content_width(v.$content_width_s);
  }
  .c__error_message {
    &:not(.all_error_messages) {
      margin: f.clamp_prf(10px, 10px) auto f.clamp_prf(20px, 40px) 252px;
      @include m.sp {
        margin-top: -18px;
        margin-left: 44px;
      }
    }
  }
  .input_wrapper {
    display: flex;
    gap: 10px;
    align-items: baseline;
    margin-bottom: 20px;
    &.wide_margin {
      margin: 28px auto;
    }
    @include m.sp {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 30px;
      &.wide_margin {
        margin: 30px auto 40px;
      }
    }
  }
  .contact_description {
    margin-top: f.clamp_prf(66px, 76px);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  form {
    margin-top: f.clamp_prf(66px, 76px);
  }

  .radio_buttons_wrapper {
    display: flex;
    gap: clamp(12px, 5vw, 24px);
    margin-left: 20px;
    @include m.sp {
      margin-left: clamp(44px, 17vw, 64px);
    }
  }
  .c__contact_label {
    display: block;
    @include m.sp {
      max-width: none;
    }
  }
  .c__contact_textbox {
    @include m.sp {
      width: calc(100% - 44px);
      margin-left: 44px;
    }
  }
  .c__contact_textarea {
    @include m.sp {
      width: calc(100% - 44px);
      margin-left: 44px;
    }
  }
  .textarea_wrapper {
    margin-bottom: 24px;
    overflow: hidden;
  }
  .agreement_wrapper a {
    text-decoration: underline;
    &:hover {
      transition: 0.3s;
      opacity: 0.8;
    }
  }
  // エラーをまとめて表示
  #js_err_existence {
    font-size: f.clamp_prf(14px, 16px);
    font-weight: v.$fw_medium_500;
    display: none;
    gap: 10px;
    flex-direction: column;
    margin-bottom: f.clamp_prf(40px, 60px);
    &.active {
      display: flex;
    }
    .u__error_list {
      display: block;
      font-size: f.clamp_prf(12px, 14px);
    }
  }
  .c__primary_button {
    margin: f.clamp_prf(40px, 100px) auto 0;
  }
}

#p__contact_confirm {
  padding-top: f.clamp_prf(calc(v.$header_height_sp), calc(v.$header_height_sp));
  margin-bottom: f.clamp_prf(80px, 200px);
  .c__head_primary {
    margin: f.clamp_prf(66px, 76px) auto f.clamp_prf(40px, 90px);
  }
  .contact_inner {
    @include m.content_width(v.$content_width_s);
  }
  .input_wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 46px;
    @include m.sp {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 40px;
    }
  }
  .label {
    font-size: 16px;
    font-weight: v.$fw_bold_700;
    color: v.$letter_primary;
    letter-spacing: 0.06em;
    cursor: text;
    width: 100%;
    max-width: f.clamp_prf(218px, 262px);
    padding-left: 44px;
    position: relative;
  }
  .value {
    font-size: 14px;
    font-weight: v.$fw_bold_700;
    color: v.$letter_primary;
    letter-spacing: 0.06em;
    cursor: text;
    width: 100%;
    line-height: normal;
    &.radio {
      position: relative;
      padding-left: 26px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(calc(-50% + 1px));
        width: 16px;
        height: 16px;
        border-radius: 100vh;
        background-color: v.$blue;
      }
    }
    @include m.sp {
      width: calc(100% - 64px);
      margin-left: 64px;
    }
  }
  .button_wrapper {
    margin-top: f.clamp_prf(60px, 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @include m.sp {
      flex-direction: column;
    }
  }
}

#p__contact_submitted {
  @include m.content_width(v.$content_width_s);
  padding-top: calc(v.$header_height);
  margin-bottom: f.clamp_prf(80px, 166px);

  @include m.tab {
    padding-top: calc(v.$header_height_sp);
  }
  .c__head_primary {
    margin: 76px auto 90px;
    @include m.sp {
      margin: 66px auto 40px;
    }
  }
  .submitted_description {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    line-height: 228.6%;
    @include m.sp {
      text-align: left;
      line-height: 200%;
    }
  }
  .c__primary_button {
    margin: f.clamp_prf(40px, 100px) auto 0;
  }
}

/*=======================================
  Terms
=======================================*/
#p__terms {
  @include m.content_width;
  margin-bottom: f.clamp_prf(80px, 200px);
  .c__head_primary {
    margin: 0 auto;
  }
  .terms_inner {
    max-width: v.$content_width_s;
    margin: 0 auto;
    padding-top: f.clamp_auto_pc2sp(140px);
    display: flex;
    flex-direction: column;
    gap: f.clamp_auto_pc2sp(100px);
  }
  .c__head_secondary {
    font-size: clamp(22px, 6vw, 26px);
  }
  .section_header {
    font-size: 18px;
    font-weight: v.$fw_medium_500;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  .article_block {
    padding-top: f.clamp_auto_pc2sp(50px);
  }
  ul {
    list-style-type: disc;
    padding: 14px 0 14px 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    li {
      font-size: 15px;
      line-height: 1.5em;
    }
  }
  .txt {
    font-size: 14px;
    text-align: left;
    line-height: 1.8;
    letter-spacing: 0.08em;
    a {
      text-decoration: underline;
    }
  }
}

/*=======================================
  404
=======================================*/
#p__notfound {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include m.content_width;
  padding-top: calc(v.$header_height + 90px);
  padding-bottom: clamp(80px, 13vw, 120px);
  .c__head_primary {
    font-size: f.clamp_prf(36px, 120px);
    line-height: 1.3;
  }
  .c__common_text {
    margin-top: f.clamp_auto_pc2sp(80px);
  }
  .c__primary_button {
    margin-top: f.clamp_auto_pc2sp(80px);
  }
}
