@use "../foundation/mixin" as m;

.u__ib {
  display: inline-block;
}
.u__il {
  display: inline;
}
.u__bl {
  display: inline;
}
.u__none {
  display: none;
}
.u__pc_none {
  display: none;
  @include m.tab {
    display: block;
  }
  @include m.sp {
    display: block;
  }
}
.u__pc_only {
  display: block;
  @include m.tab {
    display: none;
  }
  @include m.sp {
    display: none;
  }
}
.u__tab_none {
  display: block;
  @include m.tab {
    display: none;
  }
  @include m.sp {
    display: block;
  }
}
.u__tab_only {
  display: none;
  @include m.tab {
    display: block;
  }
  @include m.sp {
    display: none;
  }
}
.u__sp_none {
  display: block;
  @include m.sp {
    display: none;
  }
}
.u__sp_only {
  display: none;
  @include m.sp {
    display: block;
  }
}
