// 色系
$red: #EF3A3A;
$blue: #0064D4;
$gray: #f0f0f0;

// 文字色
$letter_primary: #000;
$letter_secondary: $gray;

// 背景色
$bg_primary: $gray;
$bg_secondary: $blue;

// テキスト選択色
$selection_bg: $blue;
$selection_text_color: #fff;
$selection_img_bg: #00000040;

// フォント系
$noto_yakuhan: "YakuHanJP", "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$noto: "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$geologica: 'Geologica', "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //extralight: 200 semibold: 600
$fw_exlight_200: 200;
$fw_light_300: 300;
$fw_regular_400: 400;
$fw_medium_500: 500;
$fw_semibold_600: 600;
$fw_bold_700: 700;

//ヘッダーフッターの高さ
$header_height: 100px;
$header_height_sp: 60px;

// 縦横比を固定する場合に使用
$ratio_16_9: 56.25%;
$ratio_4_3: 75%;
$ratio_3_2: 66.67%;
$ratio_1_1: 100%;

// メディアクエリー用
$home_swiper_break: 1420px;
$pc_min: 1108px; // pcコンテンツ幅
$tab_max: 920px;
$sp_max: 712px;
$layout_min: 320px;
$content_pd_pc: 140px;
$body_pd: clamp(30px, 6vw, 60px);
$content_width_m: 800px;
$content_width_s: 748px;

// vw変換用(デザインデータキャンバス幅pxに合わせる)
$vp_basis_pc: 1920;
$vp_basis_sp: 375;

/*=======================================
  z-index管理
=========================================

---- 99999 ----
#mouse_stalker

---- 1000 -----


---- 900 -----


----- 20 -----
#header_toggle


----- 15 -----
#sp_menu

----- 12 -----


----- 11 -----

----- 10 -----
#header

----- 9 -----


----- 8 -----

----- 7 -----
#c__virtual_estate_button

------ 5 ------


------ 2 ------
#first_view

------ 1 ------


------ -1 ------



------ -10 -----


------ -50 -----

------ -100 ----


------ -1000 ----


*/