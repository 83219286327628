@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*==============================
文字種
==============================*/
.c__common_text {
  font-size: clamp(14px, 2vw, 18px);
  color: v.$letter_primary;
  letter-spacing: 0.05em;
  line-height: 2;
  text-align: justify;
}
.c__error_message {
  color: v.$red;
  font-weight: v.$fw_medium_500;
  font-size: f.clamp_prf(12px, 14px);
  line-height: 1.5;
  display: none;
  &.active {
    display: block;
  }
}
.c__text_underline {
  position: relative;
  width: 100%;
  padding-bottom: 0.15em;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #bbb;
  }
}

// 改行しても背景がぶつ切りにならないやつ
.c__box_decoration_break_clone {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  box-shadow:
    8px 8px 10px 0 #ff1492,
    -5px -5px 5px 0 #00f,
    5px 5px 15px 0 #ff0;
}

.c__article_notfound {
  margin: 80px auto 80px;
  text-align: center;
  &.disabled {
    display: none;
  }
}
/*==============================
見出し系
==============================*/
.c__head_primary {
  display: block;
  width: fit-content;
  height: f.clamp_prf(24px, 40px);
  &.descender {
    height: f.clamp_prf(32px, 54px); // 下に突き抜けているアルファベットがある場合
  }
  img {
    display: block;
    width: auto;
    height: 100%;
  }
}
.c__head_secondary {
  display: block;
  width: fit-content;
  font-size: f.clamp_prf(40px, 80px);
  font-weight: v.$fw_bold_700;
  font-family: v.$geologica;
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(60);
  position: relative;
}
/*==============================
ボタン系
==============================*/
.c__primary_button {
  width: f.clamp_prf(208px, 264px);
  height: 60px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vh;
  font-weight: v.$fw_bold_700;
  font-size: f.clamp_prf(14px, 16px);
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 26px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 100vh;
    background-color: v.$blue;
    transition: transform 0.2s;
  }
  &.back::before {
    right: auto;
    left: 26px;
  }
  @include m.hover_device {
    &:hover {
      &:before {
        transform: translateY(-50%) scale(2);
      }
    }
  }
}
.c__navigation_button {
  width: 60px;
  height: 60px;
  background-color: v.$blue;
  border-radius: 100vh;
  cursor: pointer;
  position: relative;
  pointer-events: auto;
  transition: transform 0.2s;
  &.gray {
    background-color: v.$gray;
  }
  &.bl_thin {
    border: solid 1px v.$blue;
    background-color: transparent;
  }
  // デフォルトは右向き
  img {
    width: 24%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    transform-origin: center;
    transition: transform 0.2s;
    pointer-events: none;
  }
  &.left img {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  &.down img {
    transform: translate(-50%, -44%) rotate(90deg);
  }
  @include m.hover_device {
    &:hover {
      transform: scale(1.2);
    }
  }
}
#c__virtual_estate_button {
  position: fixed;
  bottom: clamp(10px, 3vw, 20px);
  right: clamp(10px, 3vw, 20px);
  z-index: 7;
  margin: 0 auto;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: 0.3s cubic-bezier(0.35, 2.5, 0.62, 0.82);
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  a {
    display: block;
    position: relative;
    width: clamp(120px, 9vw, 150px);
    height: clamp(120px, 9vw, 150px);
    transition: 0.6s;
    margin: 0 0 0 auto;
    pointer-events: auto;
    @include m.sp {
      width: clamp(80px, 15vw, 120px);
      height: clamp(80px, 15vw, 120px);
    }
    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
    }
    img.frame {
      // transition: 3s ease;
      animation: rotateButton 15s linear infinite;
      @keyframes rotateButton {
        100% {
          transform: rotate(360deg);
        }
      }
    }
    @include m.hover_device {
      &:hover {
        transform: scale(1.05);
        // img.frame {
        //   transition: 20s ease-out;
        //   transform: rotate(720deg);
        // }
      }
    }
  }
}
/*==============================
一覧系
==============================*/
.c__articles_grid {
  display: grid;
  gap: f.clamp_prf(14px, 20px) f.clamp_prf(14px, 20px);
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  transition: 0.2s;
  &.disabled {
    opacity: 0;
  }
  .anchor {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    animation: appear_delay 0.5s ease forwards;
    @keyframes appear_delay {
      100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        pointer-events: auto;
      }
    }
    @include m.anim_delay;
    .article_info {
      display: block;
      position: relative;
      overflow: hidden;
      pointer-events: none;
      border-radius: 12px;
      &:before {
        content: "";
        display: block;
        padding-top: v.$ratio_16_9;
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: #0064d499;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .info {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 20px 20px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        font-size: 12px;
        color: #fff;
        z-index: 1;
        font-weight: v.$fw_semibold_600;
        opacity: 0;
        transition: opacity 0.2s;
      }
      .title {
        @include m.line_clamp(1);
      }
    }
    @include m.hover_device {
      &:hover {
        .article_info {
          &:after {
            opacity: 1;
          }
          .info {
            opacity: 1;
          }
        }
      }
    }
    &.active {
      .article_info {
        transform: scale(120%);
        transition: 0.3s;
        img {
          opacity: 0;
          filter: blur(10px);
          transition: 0.3s;
        }
      }
    }
  }
  @include m.tab {
    grid-template-columns: repeat(auto-fill, minmax(298px, 1fr));
  }
  @include m.sp {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}
.c__categories_list {
  display: flex;
  flex-wrap: nowrap;
  gap: f.clamp_prf(30px, 80px) 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: v.$geologica;
  font-size: 16px;
  font-weight: v.$fw_semibold_600;
  &.failed {
    height: 0;
    display: none;
    transform: none;
  }
  @include m.sp {
    flex-wrap: wrap;
  }
  li {
    display: inline-block;
    width: clamp(96px, 12vw, 100px); // 幅を均等にするため明示
    white-space: nowrap;
    font-size: 16px;
    @include m.sp {
      width: clamp(64px, 20vw, 80px); // 幅を均等にするため明示
      font-size: clamp(14px, 4.5vw, 18px);
    }
    .category_buttons {
      position: relative;
      padding-bottom: 34px;
      display: block;
      text-align: center;
      color: transparent;
      background-image: linear-gradient(90deg, v.$blue 50%, v.$letter_primary 50.1%);
      -webkit-background-clip: text;
      background-size: 1px;
      background-size: 200%, 100%;
      background-position: 100%, 0%;
      background-repeat: no-repeat;
      transition: background-position 4s cubic-bezier(0, 1.01, 0.15, 0.99);

      @include m.sp {
        padding-bottom: 22px;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        width: 6px;
        height: 6px;
        border-radius: 100vh;
        background-color: v.$blue;
        transition: transform 0.15s;
      }
      &.active {
        background-position: 0%, 0%;
        &:before {
          transform: translateX(-50%) scale(1.6);
        }
      }
      @include m.hover_device {
        &:hover {
          background-position: 0%, 0%;
          &:before {
            transform: translateX(-50%) scale(1.5);
          }
        }
      }
    }
  }
}
.c__topics_list {
  display: flex;
  flex-direction: column;
  gap: f.clamp_prf(28px, 30px); // アイテムの間隔
  transition: 0.1s;
  &.disabled {
    opacity: 0;
  }
  .topics_item {
    display: flex;
    flex-direction: column;
    gap: f.clamp_prf(28px, 30px); // 記事とデコレーションの間隔
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
    animation: appear_delay 0.5s ease forwards;
    @include m.sp {
      gap: 0;
    }
    @keyframes appear_delay {
      100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        pointer-events: auto;
      }
    }
    @include m.anim_delay(0.1);
    &.active {
      .article_info {
        transform: scale(120%);
        transition: 0.3s;
        img {
          opacity: 0;
          filter: blur(10px);
          transition: 0.3s;
        }
      }
    }
  }
  .topics_item_info {
    display: flex;
    gap: f.clamp_prf(14px, 30px);
    // padding: f.clamp_prf(20px, 32px) 0;
    width: 100%;
    position: relative;

    .info_l {
      width: f.clamp_prf(130px, 180px);
      flex-shrink: 0;
      .thumbnail_wrapper {
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
        border-radius: 12px;
        overflow: hidden;
      }
      .thumbnail {
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        transition: 0.3s ease;
      }
    }
    .info_r {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .info_r_sub_info {
        display: flex;
        gap: f.clamp_prf(10px, 20px);
        align-items: baseline;
        @include m.sp {
          // margin-top: 6px;
          gap: 6px;
          flex-direction: column;
        }
      }
      .date {
        font-size: f.clamp_prf(14px, 14px);
        white-space: nowrap;
        font-weight: v.$fw_bold_700;
        line-height: 1.5;
        transform: translateY(1px);
      }
      .hashtags {
        // display: flex;
        // flex-wrap: wrap;
        gap: 6px f.clamp_prf(12px, 15px);
        font-size: f.clamp_prf(11px, 12px);
        font-weight: v.$fw_bold_700;
        @include m.line_clamp(2);
        @include m.sp {
          gap: 6px 12px;
          @include m.line_clamp(3);
        }
        .hashtag {
          display: inline-block;
          margin-right: 1em;
          line-height: 1.5;
        }
      }
    }
  }
  .topics_title {
    font-weight: v.$fw_bold_700;
    font-size: f.clamp_prf(16px, 21px);
    line-height: 147.826%;
    @include m.line_clamp(2);
    color: v.$blue;
    @include m.sp {
      margin-top: 20px;
    }
  }
  .topics_item_decorations {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    @include m.sp {
      margin-top: 26px;
    }
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 100vh;
      background-color: v.$blue;
    }
    .line {
      position: relative;
      height: 1px;
      width: 100%;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #d9d9d9;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: v.$blue;
        transform: scaleX(0);
        transform-origin: left;
        transition: 0.3s ease;
      }
      width: 100%;
    }
  }

  // ホバー時のアニメーション
  .topics_item {
    &:hover {
      .thumbnail {
        transform: scale(1.05);
      }
      .line {
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }
}
.c__topics_category_list {
  display: flex;
  flex-wrap: nowrap;
  @include m.sp {
    flex-wrap: wrap;
    gap: 10px 0;
  }
  li {
    width: 100%;
    height: 40px;
    @include m.sp {
      width: 50%;
    }
  }
  .category_buttons {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    margin: 0 auto;
    font-weight: v.$fw_bold_700;
    border-top: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    transition: 0.1s;
    // 最初の要素
    &:first-child {
      border-left: 1px solid #d9d9d9;
    }
    &.active {
      color: #fff;
      background-color: v.$blue;
      border-top: 1px solid v.$blue;
      border-right: 1px solid v.$blue;
      border-bottom: 1px solid v.$blue;
      // 最初の要素
      &:first-child {
        border-left: 1px solid v.$blue;
      }
    }
    @include m.hover_device {
      // .activeじゃない要素のhover時
      &:not(.active):hover {
        background-color: #d9d9d9;
        // color: v.$letter_primary;
      }
    }
  }
}
.c__topics_tag_list {
  display: flex;
  gap: 16px;
  flex-direction: column;
  .category_buttons {
    font-weight: v.$fw_bold_700;
    font-size: f.clamp_prf(14px, 14px);
    // @include m.line_clamp(1);
    padding: 0em 0.3em;
    line-height: 2;
    transition: 0.1s ease;
    text-align: left;
    &.active {
      background-color: v.$blue;
      color: #fff;
    }
    @include m.hover_device {
      &:hover {
        background-color: #d9d9d9;
        @include m.tab {
          background-color: #bbb;
        }
        color: v.$letter_primary;
        &.active {
          background-color: v.$blue;
          color: #fff;
        }
      }
    }
  }
  @include m.tab {
    gap: 6px;
    flex-direction: row;
  }
}
.c__pager {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-weight: v.$fw_exlight_200;
  font-family: v.$geologica;
  font-size: 14px;
  // li {
  .page_num {
    display: block;
    padding: 0.6em;
    width: 100%;
    height: 100%;
    transition: 1.15s;
    &.active {
      color: v.$blue;
    }
    @include m.hover_device {
      &:hover {
        color: v.$blue;
      }
    }
  }
  // }
  .disabled {
    display: none;
  }
  .elipsis {
    letter-spacing: 0.05em;
  }
  .underline {
    transition: 1.15s;
    padding-bottom: 10px;
    position: relative;
    &:before {
      transition: 0.5s;
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: max(100%, 10px);
      height: 1px;
      background-color: transparent;
      transition: all 0.3s;
      transform: scale(0, 1) translateX(-50%);
      transform-origin: center top;
      @include m.sp {
      }
    }
    &.active {
      pointer-events: none;
      &:before {
        transform: scale(1, 1) translateX(-50%);
        background-color: #fff;
      }
    }
    @include m.hover_device {
      &:hover {
        &:before {
          transform: scale(1, 1) translateX(-50%);
          background-color: #fff;
        }
      }
    }
  }
  .prev_link,
  .next_link {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    background-color: v.$blue;
    border-radius: 100vh;
    font-size: 14px;
    transition: 0.15s;
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-image: url(/assets/img/icon/arrow_wh.svg);
      background-repeat: no-repeat;
      width: 40%;
      height: 40%;
      position: absolute;
      top: 50%;
      left: 50%;

      background-position: center;
    }
    @include m.hover_device {
      &:hover {
        // &:before {
        transform: scale(1.1);
        // }
      }
    }
  }
  .prev_link {
    margin-right: clamp(16px, 0.98vw, 20px);
    &:before {
      // left: 0;
      // transform: translate(0, -53%) rotate(180deg);
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  .next_link {
    margin-left: clamp(16px, 0.98vw, 20px);
    &:before {
      // left: 0;
      // transform: translate(0, -50%);
      transform: translate(-43%, -50%);
    }
  }
}
/*=======================================
  記事ページ
=======================================*/
.c__article_wrapper {
  z-index: 5;
  position: relative;
  height: 200vh;
  &.loaded {
    height: auto;
  }
  .article_head_info_first {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .article_head_info_second {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: f.clamp_prf(10px, 10px);
  }
  .article_category {
    font-weight: v.$fw_bold_700;
    font-size: f.clamp_prf(14px, 16px);
    color: v.$blue;
  }
  .article_title {
    font-size: f.clamp_prf(16px, 22px);
    font-weight: v.$fw_bold_700;
    line-height: 162.5%;
  }
  .completed_at {
    font-size: f.clamp_prf(14px, 16px);
    font-weight: v.$fw_bold_700;
  }
  .article_thumbnail {
    position: relative;
    padding-top: v.$ratio_16_9;
    margin-top: f.clamp_prf(10px, 20px);
    img,
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .main_content {
    margin-top: f.clamp_prf(18px, 30px);
  }
  .sub_contents {
    display: flex;
    gap: clamp(40px, 3vw, 50px);
    flex-wrap: nowrap;
    margin-top: clamp(18px, 6vw, 30px);
    @include m.sp {
      flex-direction: column;
      gap: f.clamp_prf(18px, 24px);
    }
    .c__article_body {
      font-size: f.clamp_prf(14px, 15px);
    }
    .credit_wrapper {
      display: flex;
      gap: clamp(10px, 1vw, 20px);
      flex-grow: 1;
      width: 120%;
      @include m.sp {
        width: 100%;
      }
    }
    .credit_wrapper_head {
      font-size: f.clamp_prf(14px, 15px);
      font-weight: v.$fw_bold_700;
      line-height: 200%;
      width: clamp(46px, 8vw, 50px);
      flex-shrink: 0;
    }
    .caption {
      flex-grow: 1;
      width: 100%;
      @include m.sp {
        padding-left: calc(
          clamp(46px, 8vw, 50px) + clamp(10px, 1vw, 20px)
        ); // credit_wrapper_headの幅 + credit_wrapperのgap
      }
    }
  }
}

/*=======================================
  コンテンツエリア
=======================================*/
.c__article_body {
  $article_paragraph_mb: f.clamp_prf(18px, 24px);
  font-family: v.$noto;
  font-size: f.clamp_prf(14px, 16px);
  font-weight: v.$fw_regular_400;
  color: v.$letter_primary;
  // letter-spacing: 0.15em;
  h2 {
    font-size: f.clamp_prf(20px, 26px);
    font-weight: v.$fw_semibold_600;
    margin-bottom: $article_paragraph_mb;
    line-height: 200%;
  }
  h3 {
    font-size: f.clamp_prf(18px, 24px);
    font-weight: v.$fw_semibold_600;
    margin-bottom: $article_paragraph_mb;
    line-height: 200%;
  }
  h4 {
    font-size: f.clamp_prf(17px, 22px);
    font-weight: v.$fw_semibold_600;
    margin-bottom: $article_paragraph_mb;
    line-height: 200%;
  }
  h5 {
    font-size: f.clamp_prf(16px, 20px);
    font-weight: v.$fw_semibold_600;
    margin-bottom: $article_paragraph_mb;
    line-height: 200%;
  }
  h6 {
    font-size: f.clamp_prf(15px, 20px);
    font-weight: v.$fw_medium_500;
    margin-bottom: $article_paragraph_mb;
    line-height: 200%;
  }
  p {
    font-size: 1em;
    margin-bottom: $article_paragraph_mb;
    line-height: 200%;
    text-align: justify;
    strong {
      font-weight: v.$fw_semibold_600;
    }
    em {
      font-style: italic;
    }
    u {
      text-decoration: underline;
    }
    s {
      text-decoration: line-through;
    }
    a {
      text-decoration: underline;
      color: v.$blue;
      transition: 0.15s;
      &:hover {
        opacity: 0.7;
      }
    }
    img {
      width: 100%;
      border-radius: 12px;
    }
  }
  ul {
    margin-bottom: $article_paragraph_mb;
    list-style-type: disc;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 1em;
      line-height: 1.5;
    }
  }
  ol {
    margin-bottom: $article_paragraph_mb;
    list-style-type: decimal;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 1em;
      line-height: 1.5;
    }
  }
  figure {
    margin-bottom: $article_paragraph_mb;
  }
  img {
    width: 100%;
    border-radius: 12px;
  }
  figcaption {
    font-size: 1em;
    line-height: 1.8;
  }
  .wp-block-embed-youtube .wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border-radius: 12px;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  iframe {
    width: 100%;
    height: clamp(200px, 47.5vw, 450px);
    @include m.sp {
      height: clamp(150px, 48vw, 400px);
    }
  }
}

// iframe 16:9固定
.c__iframe_wrapper {
  margin-bottom: 60px;
  position: relative;
  padding-bottom: 56.25%; // アスペクト比 16:9の場合の縦幅
  height: 0;
  overflow: hidden;
  border-radius: 12px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/*=======================================
  フォーム系
=======================================*/
@mixin form_base($height, $font_size: 16px) {
  width: 100%;
  height: $height;
  font-family: v.$noto; //要明示
  font-size: $font_size;
  padding: 12px 20px;
  transition: 0.2s ease;
  color: v.$letter_primary;
  -webkit-appearance: none; //safariの角丸対策
  border-radius: 0px; //safariの角丸対策
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  outline: 0;
  background-color: rgba(#fff, 1);
  line-height: 1.5;
  letter-spacing: 0.03em;
  border-radius: 100vh;
  &:focus {
    // border: 1px solid #ddddddff;
    outline: 0;
    // border-radius: 100vh;
    box-shadow: 0px 0px 4px 1px #ccc; //横ずれ 縦ずれ ぼかし度 サイズ 色
  }
  &::placeholder {
    color: #d9d9d9;
    font-family: v.$noto; //明示
    font-size: $font_size;
  }
}
.c__contact_label {
  font-size: 16px;
  font-weight: v.$fw_bold_700;
  color: v.$letter_primary;
  letter-spacing: 0.06em;
  cursor: text;
  width: 100%;
  max-width: f.clamp_prf(200px, 242px);
  padding-left: 44px;
  position: relative;
}
.c__contact_textbox {
  @include form_base(40px, 14px);
  @include m.sp {
    height: 48px;
    padding: 20px 18px;
  }
}
.c__contact_textarea {
  @include form_base(426px);
  // max-height: 426px;
  resize: none;
  border-radius: 20px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
  }
  @include m.sp {
    margin-left: 44px;
  }
}

// ラジオボタン・チェックボックス
.c__contact_radio,
.c__contact_checkbox {
  display: none;

  // 文字
  + label {
    position: relative;
    padding-left: 30px; // 文字をチェックボックスの間にスペースを空ける
    cursor: pointer;
    font-size: clamp(12px, 4vw, 14px);
    font-weight: v.$fw_bold_700;
    width: fit-content;
    white-space: nowrap;
  }

  // チェックボックスそのもののデザイン
  + label:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-0%, -44%);
    content: "";
    // border: 1px solid #aaa;
    background-color: #fff;
    border-radius: 100vh;
    width: 20px;
    height: 20px;
    opacity: 1;
    transition: 0.3s;
  }

  // チェックボックスチェック時のデザイン
  &:checked + label:before {
    background-color: v.$blue;
  }

  // チェックボックスホバー時のデザイン
  @include m.hover_device {
    &:hover {
      + label:before {
        background-color: #0064d455;
      }
      &:checked + label:before {
        background-color: v.$blue;
      }
    }
  }
}

.c__contact_select {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  &:after {
    // 三角
    position: absolute;
    right: 15px;
    width: 10px;
    height: 7px;
    background-color: #fff;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    content: "";
    pointer-events: none;
  }
  select {
    appearance: none; //各ブラウザのデフォルトをリセット
    width: 100%;
    @include form_base(60px, 16px);
    cursor: pointer;
    color: v.$letter_primary;
    -webkit-appearance: none; //safariの角丸対策
    border-radius: 0px; //safariの角丸対策
    border: none;
    outline: 0;
  }
  option {
    color: v.$letter_primary;
    font-family: v.$noto; //明示
    font-size: 16px;
    background: #333;
  }
}
.required::after {
  position: absolute;
  content: "必須";
  color: v.$blue;
  font-size: 12px;
  font-weight: v.$fw_bold_700;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
/*==============================
  装飾系
==============================*/
.c__external_link {
  @include m.external_link_icon;
}

/*=======================================
  アニメーション系
=======================================*/
.c__js_fade {
  transform: translate(0, 40px);
  opacity: 0;
  transition: 0.3s ease;
  &.active {
    transform: translate(0, 0) scale(100%);
    opacity: 1;
  }
  @include m.tran_delay(0.1);
}

// ディレイを付けたい場合
// 親要素に c__js_fade_delay を付け、子要素に js__delay_child delay2 のようなクラスを付ける
.c__js_fade_delay {
  .js__delay_child {
    transform: translate(0, 40px);
    opacity: 0;
  }
  &.active {
    .js__delay_child {
      transform: translate(0, 0) scale(100%);
      opacity: 1;
      transition: 0.5s ease;
      @include m.tran_delay(0.06);
    }
  }
}

.c__js_fade_repeat {
  transform: translate(0, 40px);
  opacity: 0;
  transition: 0.3s ease;
  &.active {
    transform: translate(0, 0) scale(100%);
    opacity: 1;
  }
  @include m.tran_delay(0.1);
}
.c__js_blur {
  .c__js_blur_child {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    filter: blur(10px);
  }
  &.active {
    .c__js_blur_child {
      opacity: 1;
      filter: blur(0px);
      @include m.tran_delay(0.2);
    }
  }
}
.js__transition {
  .js__transition_child {
    // .js__transition_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include m.tran_delay(0.2);
  }
}
.js__animation {
  .js__animation_child {
    // .js__animation_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include m.anim_delay(0.2);
  }
}
.js__parallax_container {
  transition: 0.25s cubic-bezier(0.08, 0.93, 0.42, 0.96);
}

// あくまで表示非表示の切り替えのみ行う
.js__load_required {
  opacity: 0;
  pointer-events: none;
  height: 100vh;
  transform: translate(0, 20px);
  transition: 0.2s ease;
  &.loaded {
    transform: translate(0, 0) scale(100%);
    opacity: 1;
    pointer-events: auto;
    height: auto;
  }
}
