@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*=======================================
  ヘッダー
=======================================*/
#header {
  width: 100%;
  font-family: v.$geologica;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: v.$header_height;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 0 auto;
  pointer-events: none;
  @include m.sp {
    height: v.$header_height_sp;
  }
  .header_l {
    width: 128px;
    height: 100%;
    transition: 0.2s ease;
    &.disabled {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
    .logo_anchor {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #0a0a0a;
      border-radius: 0px 0px f.clamp_prf(20px, 40px) 0px;
      pointer-events: auto;
      img {
        display: block;
        width: 66px;
        margin-right: 30px;
      }
    }
    @include m.sp {
      width: 80px;
      .logo_anchor {
        img {
          width: 44px;
          margin-right: 18px;
        }
      }
    }
    @include m.hover_device {
      &:hover {
        width: 140px;
      }
      @include m.sp {
        &:hover {
          width: 90px;
        }
      }
    }
  }
  .header_r {
    position: absolute;
    top: 20px;
    right: 48px;
    font-size: clamp(14px, 1.8vw, 14px);
    font-weight: v.$fw_semibold_600;
    letter-spacing: f.xd_letter_spacing(60);
    display: flex;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: all 0.6s ease-in-out;
    // margin-right: 60px;
    &.disabled {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
    @include m.sp {
      display: none;
    }
    .link_wrapper {
      display: flex;
      gap: 14px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
    a {
      display: block;
      text-align: right;
      position: relative;
      pointer-events: auto;
      color: transparent;
      background-image: linear-gradient(90deg, v.$blue 50%, v.$letter_primary 50.1%);
      -webkit-background-clip: text;
      background-size: 1px;
      background-size: 200%, 100%;
      background-position: 100%, 0%;
      background-repeat: no-repeat;
      transition: background-position 1s cubic-bezier(0, 1.01, 0.15, 0.99);
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: -16px;
        width: 6px;
        height: 6px;
        background-color: v.$blue;
        transform: translateY(-50%);
        border-radius: 100vh;
        transition: 0.15s;
        transform-origin: center;
      }
      @include m.hover_device {
        &:hover {
          background-position: 0%, 0%;
          &:before {
            transform: translateY(-50%) scale(1.5);
          }
        }
      }
    }
  }
  // FVの上にヘッダーがある時
  .header_r.on_fv {
    a {
      &:before {
        background-color: #fff;
        opacity: 0.7;
      }
      // ホバーしても文字の色を変化させない
      @include m.hover_device {
        &:hover {
          background-position: 100%, 0%;
        }
      }
    }
  }

  #header_toggle {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    z-index: 20;
    background-color: #000;
    border-radius: 100vh;
    cursor: pointer;
    pointer-events: auto;
    transition: opacity 0.6s ease;
    &.disabled {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
    @include m.sp {
      display: block;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }
    span {
      display: block;
      position: absolute;
      width: 24px;
      height: 2px;
      background-color: #fff;
      transition: 0.3s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
    }
  }
  $header_toggle_top: 20px;
  $header_toggle_top_offset: 10px;
  $header_toggle_right: 18px;
  #header_toggle span:nth-child(1) {
    top: $header_toggle_top;
    right: $header_toggle_right;
  }
  #header_toggle span:nth-child(2) {
    top: calc($header_toggle_top + $header_toggle_top_offset);
    right: $header_toggle_right;
  }
  #header_toggle span:nth-child(3) {
    top: calc($header_toggle_top + ($header_toggle_top_offset * 2));
    right: $header_toggle_right;
  }
  #header_toggle.active span:nth-child(1) {
    transform: translate(0, 10px) rotate(225deg);
  }
  #header_toggle.active span:nth-child(2) {
    transform: translate(50px, 0) scale(0);
  }
  #header_toggle.active span:nth-child(3) {
    transform: translate(0, -10px) rotate(-225deg);
  }
}
#sp_menu {
  height: f.svh(100);
  height: 100dvh;
  width: 100%;
  transition: all 0.3s ease-in-out;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 15;
  &.active {
    @include m.sp {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }
  .menu_inner {
    visibility: inherit;
    opacity: inherit;
    width: 100%;
    margin: calc(v.$header_height_sp + clamp(0px, 5vh, 66px)) auto 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: clamp(18px, 5vh, 24px);
  }
  .link_name {
    font-family: v.$geologica;
    font-weight: v.$fw_semibold_600;
    font-size: clamp(30px, 5vmin, 30px);
    line-height: 1;
    margin-right: clamp(40px, 15.8vw, 76px);
    color: #fff;
    position: relative;
    flex-shrink: 0;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: -26px;
      width: 10px;
      height: 10px;
      background-color: v.$blue;
      transform: translateY(-50%);
      border-radius: 100vh;
    }
  }
  .sp_menu_bottom_info {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: clamp(20px, 5vw, 30px);
    padding: clamp(30px, 13.8vw, 50px);
    .info_l {
      width: clamp(100px, 34vw, 136px);
    }
    .info_r {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 10px;
      color: #fff;
      font-size: 12px;
      font-weight: v.$fw_semibold_600;
      white-space: nowrap;
    }
  }
}

.l__main_content {
  padding-top: clamp(80px, 13vw, 120px);
}

//コンテンツ幅内でめいっぱい広げる
.l__content_width {
  @include m.content_width;
}

/*=======================================
  フッター
=======================================*/
#footer {
  width: 100%;
  height: 210px;
  background-color: v.$blue;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.2s;
  &.active {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  @include m.sp {
    height: 194px;
  }
  .footer_inner {
    @include m.content_width;
    margin: 0 auto;
    padding-bottom: 40px;
    font-family: v.$geologica;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: v.$fw_semibold_600;
    @include m.sp {
      padding-bottom: 20px;
    }
  }

  .footer_l {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
  }

  .footer_r {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
  }
  a {
    transition: color 0.2s;
    color: transparent;
    background-image: linear-gradient(90deg, white 50%, v.$letter_primary 50.1%);
    -webkit-background-clip: text;
    background-size: 1px;
    background-size: 210%, 100%;
    background-position: 100%, 0%;
    background-repeat: no-repeat;
    transition: background-position 0.6s cubic-bezier(0.13, 1.04, 0.24, 1);
    @include m.hover_device {
      &:hover {
        background-position: 0%, 0%;
      }
    }
  }
}

/*=======================================
  マウスストーカー
=======================================*/
#mouse_stalker {
  pointer-events: none;
  @include m.hover_device {
    position: fixed;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    background: v.$blue;
    border-radius: 100vh;
    transform: translate(0, 0);
    transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 99999;
    mix-blend-mode: multiply;
    // ====== クリック可能な要素にカーソルを乗せた際のスタイル ====== //
    &.stalker_cursor_pointer {
      top: -25px;
      left: -25px;
      width: 50px;
      height: 50px;
      opacity: 0.5;
    }
    &.disabled {
      opacity: 0;
    }
  }
  @include m.touch_device {
    display: none;
  }
}

/*=======================================
  ローディングアイコン
=======================================*/
.c__loader {
  position: relative;
  opacity: 0;
  margin: 0;
  .loader_dot {
    position: absolute;
    opacity: 0;
    border-radius: 100vh;
    width: 10px;
    height: 10px;
    background-color: v.$blue;
    left: 50%;
    transform: translateX(-50%);
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    $loader_dot_offset: 26px;
    &.first {
      top: 80px + $loader_dot_offset;
      animation-delay: -0.2s;
    }
    &.second {
      top: 80px + $loader_dot_offset * 2;
      animation-delay: -0.1s;
    }
    &.third {
      top: 80px + $loader_dot_offset * 3;
      animation-delay: 0s;
    }
  }
  @keyframes load_dots {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(1);
    }
    70% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  &.active {
    opacity: 1;
    margin: 0 auto 150vh;
    .loader_dot {
      opacity: 1;
      animation-name: load_dots;
    }
  }
}
