@use "../foundation/functions" as f;
@use "../foundation/variables" as v;

/*=======================================
  ページ制御系
=======================================*/
@mixin home_swiper_break {
  @media (max-width: (v.$home_swiper_break)) {
    @content;
  }
}
@mixin pc {
  @media (min-width: (v.$pc_min)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: (v.$tab_max)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: (v.$sp_max)) {
    @content;
  }
}
// 374px以下の小さめの端末
@mixin sp_s {
  @media (max-width: 374px) {
    @content;
  }
}
// 任意の幅以下
@mixin mq($max-width) {
  @media (max-width: ($max-width)) {
    @content;
  }
}
@mixin hover_device {
  @media (any-hover: hover) {
    @content;
  }
}
@mixin touch_device {
  @media (any-hover: none) {
    @content;
  }
}
//縦長
@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}
//横長
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin cq($name, $size) {
  @container #{$name} (max-width: $size) {
    @content;
  }
}

// 点線を生成
@mixin dashed_border($height: 1px, $dash: 3px, $spacing: 5px, $color: #aaa) {
  display: block;
  height: $height;
  position: relative;
  &::before {
    content: "";
    background-image: linear-gradient(
      to right,
      $color 0px,
      $color $dash,
      transparent $dash,
      transparent #{$dash + $spacing}
    );
    background-size: #{$dash + $spacing} $height;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
// テキストを省略
@mixin line_clamp($line) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}
// テキストを日英ともに縦書き
@mixin text_vertical {
  writing-mode: vertical-rl;
  text-orientation: sideways;
}
@mixin custom_scrollbar($height: 6px, $track_color: #d9d9d9, $thumb_color: v.$blue) {
  &::-webkit-scrollbar {
    @include hover_device {
      height: $height * 1.5;
    }
    height: $height;
  }
  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 100vh;
    background: $track_color;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb_color;
    border-radius: 100vh;
    box-shadow: none;
  }
}
@mixin fontawesome($type) {
  @if $type == fas {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_fas;
  } @else if $type == far {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_far;
  } @else if $type == fal {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_fal;
  } @else if $type == fab {
    font-family: "Font Awesome 5 Brands";
    font-weight: $fontawesome_weight_fab;
  }
}
@mixin content_width($width: v.$pc_min) {
  max-width: calc($width + v.$body_pd + v.$body_pd);
  width: 100%;
  padding-left: v.$body_pd;
  padding-right: v.$body_pd;
  margin-left: auto;
  margin-right: auto;
}
@mixin external_link_icon {
  position: relative;
  padding-right: 1.5em;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-image: url(../../img/icon/new_window_line.svg);
    background-repeat: no-repeat;
    width: 0.8em;
    height: 0.8em;
    bottom: 2px;
    right: 0.3em;
  }
}
@mixin gradation_text($color: linear-gradient(90deg, #8a2387 0%, #e94057 50%, #f27121 100%)) {
  background: $color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  &::selection {
    -webkit-background-clip: text;
    -webkit-text-fill-color: v.$selection_text_color;
    background-clip: text;
    text-fill-color: v.$selection_text_color;
  }
  span {
    &::selection {
      -webkit-background-clip: text;
      -webkit-text-fill-color: v.$selection_text_color;
      background-clip: text;
      text-fill-color: v.$selection_text_color;
    }
  }
}
// 親要素の幅を超えて画面横幅いっぱいに広げる
@mixin spread_over_parent() {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

@mixin dot_filter($size: 15px) {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: radial-gradient(#fff 8%, transparent 9%), radial-gradient(#fff 8%, transparent 9%);
    background-size: $size $size;
    background-position:
      0 0,
      calc($size / 2) calc($size / 2);
  }
}
/*===================================================
  アニメーションディレイ
  子要素のアニメーションにディレイをかけられる
===================================================*/
@mixin anim_delay($magnification: 0.05) {
  @for $i from 0 through 16 {
    &.delay#{$i} {
      animation-delay: #{$i * $magnification}s;
    }
  }
}
@mixin tran_delay($magnification: 0.05) {
  @for $i from 0 through 12 {
    &.delay#{$i} {
      transition-delay: #{$i * $magnification}s;
    }
  }
}
