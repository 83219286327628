/*==============================
フォント
==============================*/

// yakuhan
@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css');

// noto sans jp
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap');

// Geologica
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@400;500;600;700&display=swap');
